import Swiper from 'swiper';
import {Autoplay, Navigation, Pagination, Thumbs} from 'swiper/modules';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {ClickScrollPlugin, OverlayScrollbars} from 'overlayscrollbars';
import VanillaCalendar from 'vanilla-calendar-pro';

Chart.register(ChartDataLabels);

OverlayScrollbars.plugin(ClickScrollPlugin);

require('ion-rangeslider');

$('#applicationForm').on('submit-success', function(event, data) {
    // do something with the response data
    console.log(data);
    alert('Pomyślnie przesłano formularz.', 'success');
});

// b banner slider

let bBannerSlider;

$('.b-banner-slider .swiper').each(function(){

    if ($(this).parent().hasClass('swiper-rabc-promo')) {
        bBannerSlider = new Swiper($(this)[0], {
            slidesPerView: 1,
            autoplay: {
                delay: 3000,
            },
            speed: 1000,
            loop: true,
            pagination: {
                el: $(this).siblings('.swiper-pagination')[0],
                clickable: true
            },
            modules: [Pagination, Autoplay]
        });
    } else {
        bBannerSlider = new Swiper($(this)[0], {
            slidesPerView: 1,
            loop: true,
            pagination: {
                el: $(this).siblings('.swiper-pagination')[0],
                clickable: true
            },
            modules: [Pagination]
        });
    }
});

// b tab

$('.b-tab__header-check .form-check').on('click', function(e){
    e.stopPropagation();
});

$('.b-tab__header').click(function(e){

    e.preventDefault();

    $(this).parent().toggleClass('b-tab__item--active');
    $(this).siblings('.b-tab__content').slideToggle(200);

});

// b chart score

const getOrCreateLegendList = (chart, id) => {

    let legendContainer = $(chart.canvas).closest('.b-chart-score__content-item').find('.b-chart-score__legend'),
        listContainer = legendContainer.children('ul');

    if(listContainer.length == 0) {
        listContainer = document.createElement('ul');
        legendContainer.append(listContainer);
    }

    return $(listContainer)[0];

};

const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {

        const ul = getOrCreateLegendList(chart, options.containerID);

        while (ul.firstChild) {
            ul.firstChild.remove();
        }

        const items = chart.options.plugins.legend.labels.generateLabels(chart);

        items.forEach(item => {
            const li = document.createElement('li');

            li.onclick = () => {
                const {
                    type
                } = chart.config;
                if (type === 'pie' || type === 'doughnut') {
                    chart.toggleDataVisibility(item.index);
                } else {
                    chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                }
                chart.update();
            };

            const boxSpan = document.createElement('span');
            boxSpan.style.background = item.fillStyle;

            const textContainer = document.createElement('p');
            textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

            const text = document.createTextNode(item.text);
            textContainer.appendChild(text);

            li.appendChild(boxSpan);
            li.appendChild(textContainer);
            ul.appendChild(li);
        });
    }
};

$('.b-chart-score').each(function(){

    let that = $(this),
        chartType = that.find('.b-chart-score__chart').data('type'),
        chartElement = that.find('.b-chart-score__chart'),
        select = that.find('.form-select__select select'),
        data1 = that.find('.chart-container__data-1'),
        data2 = that.find('.chart-container__data-2'),
        data3 = that.find('.chart-container__data-3'),
        data4 = that.find('.chart-container__data-4'),
        nav = that.find('.b-chart-score__nav'),
        navLi = nav.find('li'),
        table = that.find('.b-chart-score__table'),
        chart,
        config,
        config_overwrite,
        dataset1,
        dataset2;

    if(chartType == 'bar') {

        let defaultConfig = {
            type: 'bar',
            data: {
                datasets: [{
                    // label: 'Rok ubiegły',
                    maxBarThickness: 16,
                    barPercentage: 0.5
                }, {
                    // label: 'Ten rok (2023)',
                    maxBarThickness: 16,
                    barPercentage: 0.5
                }]
            },
            options: {
                layout: {
                    autoPadding: false,
                    padding: {
                        top: 20
                    }
                },
                maintainAspectRatio: false,
                scales: {
                    y: {
                        border: {
                            display: false
                        },
                        ticks: {
                            display: false
                        },
                        grid: {
                            display: false
                        }
                    },
                    x: {
                        border: {
                            display: false
                        },
                        ticks: {
                            font: {
                                family: "'BridgestoneType', sans-serif",
                                weight: 400,
                                size: 14,
                                lineHeight: 1
                            }
                        },
                        grid: {
                            display: false
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        enabled: false
                    },
                    legend: {
                        display: false
                    },
                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        formatter: function(value, context) {
                            return value.toLocaleString();
                        },
                        font: {
                            family: "'BridgestoneType', sans-serif",
                            weight: 700,
                            size: 10,
                            lineHeight: 1
                        }
                    }
                }
            },
            plugins: [{
                beforeInit: function(chart, args, options) {}
            }, htmlLegendPlugin]
        };

        if(chartElement.hasClass('b-chart-score__chart--version-1')) {

            config_overwrite = {
                data: {
                    labels: data1.attr('data-values').split(","),
                    datasets: [
                        {
                            label: data1.attr('data-label-1'),
                            data: data1.attr('data-column-1').split(","),
                            backgroundColor: '#d4d4d4'
                        },
                        {
                            label: data1.attr('data-label-2'),
                            data: data1.attr('data-column-2').split(","),
                            backgroundColor: '#E1261D'
                        }
                    ]
                }
            };

        }

        if(chartElement.hasClass('b-chart-score__chart--version-2')) {

            config_overwrite = {
                data: {
                    labels: data1.attr('data-values').split(","),
                    datasets: [
                        {
                            label: data1.attr('data-label-1'),
                            data: data1.attr('data-column-1').split(","),
                            backgroundColor: '#d4d4d4'
                        },
                        {
                            label: data1.attr('data-label-2'),
                            data: data1.attr('data-column-2').split(","),
                            backgroundColor: '#555555'
                        }
                    ]
                }
            };

        }

        if(chartElement.hasClass('b-chart-score__chart--version-3')) {

            config_overwrite = {
                data: {
                    labels: data1.attr('data-values').split(","),
                    datasets: [
                        {
                            label: data1.attr('data-label-1'),
                            data: data1.attr('data-column-1').split(","),
                            backgroundColor: '#d4d4d4'
                        },
                        {
                            label: data1.attr('data-label-2'),
                            data: data1.attr('data-column-2').split(","),
                            backgroundColor: '#555555'
                        }
                    ]
                }
            };

        }

        config = $.extend(true, {}, config_overwrite, defaultConfig);

        chart = new Chart(chartElement, config);
        dataset1 = {...chart.data.datasets[0]};
        dataset2 = {...chart.data.datasets[1]};

    }

    if(chartType == 'pie') {

        let data1_parse = (new Function("return [" + data1.attr('data-column-1') + "];")()),
            data2_parse = (new Function("return [" + data2.attr('data-column-2') + "];")()),
            data3_parse = (new Function("return [" + data3.attr('data-column-3') + "];")()),
            defaultConfig = {
                type: 'pie',
                data: {
                    labels: data1.attr('data-values').split(","),
                    datasets: [
                        {
                            data: data1_parse,
                            parsing: {
                                key: 'number'
                            },
                            backgroundColor: ['#555555', '#6b6b6b', '#818181', '#979797', '#acacac', '#c0c0c0', '#cecece', '#d7d7d7', '#e0e0e0', '#e7e7e7', '#ededed', '#f3f3f3'],
                            datalabels: {
                                color: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#555555', '#555555', '#555555', '#555555', '#555555', '#555555', '#555555']
                            }
                        }
                    ]
                },
                options: {
                    layout: {
                        autoPadding: false,
                    },
                    maintainAspectRatio: false,
                    elements: {
                        arc: {
                            borderWidth: 0
                        }
                    },
                    plugins: {
                        datalabels: {
                            formatter: (value, ctx) => {

                                let total = ctx.chart.getDatasetMeta(0).total,
                                    percentage = (value.number * 100 / total).toFixed(0);

                                if(percentage >= 10) {
                                    return percentage + '%';
                                } else {
                                    return "";
                                }

                            },
                            font: {
                                weight: 'bold',
                                size: 13
                            }
                        },
                        legend: {
                            display: false
                        },
                        tooltip: {
                            callbacks: {
                                title: function() {
                                    return  "";
                                },
                                label: function(tooltipItem, data) {
                                    return tooltipItem.label;
                                },
                                footer: function(tooltipItems) {

                                    let total = tooltipItems[0].chart.getDatasetMeta(0).total,
                                        percentage = (tooltipItems[0].raw.number * 100 / total).toFixed(0);

                                    return tooltipItems[0].raw.count + " / " + percentage + "%";

                                }
                            }
                        }
                    },
                },
                plugins: [{
                    beforeInit: function(chart, args, options) {}
                }, htmlLegendPlugin]
            };

        chart = new Chart(chartElement, defaultConfig);

    }

    navLi.click(function(e){

        e.preventDefault();

        let that = $(this),
            index = that.index(),
            items = that.closest('.b-chart-score').find('.b-chart-score__content-item');

        that.addClass('active').siblings().removeClass('active');
        items.eq(index).fadeIn(200).siblings().hide();

    });

    select.on('change', function(){

        let value = $(this).val();

        if(chartType == 'bar') {

            if(value == 'month') {

                chart.data.labels = data1.attr('data-values').split(",");
                chart.data.datasets = [
                    {
                        ...dataset1,
                        data: data1.attr('data-column-1').split(",")
                    },
                    {
                        ...dataset2,
                        data: data1.attr('data-column-2').split(",")
                    }
                ]

                chartElement.parent().removeClass('chart-container--narrower');

                table.eq(0).fadeIn(200).siblings().hide();

            } else if(value == 'quarth') {

                chart.data.labels = data2.attr('data-values').split(",");
                chart.data.datasets = [
                    {
                        ...dataset1,
                        data: data2.attr('data-column-1').split(",")
                    },
                    {
                        ...dataset2,
                        data: data2.attr('data-column-2').split(",")
                    }
                ]

                chartElement.parent().addClass('chart-container--narrower');

                table.eq(1).fadeIn(200).siblings().hide();

            } else if(value == 'half-year') {

                chart.data.labels = data3.attr('data-values').split(",");
                chart.data.datasets = [
                    {
                        ...dataset1,
                        data: data3.attr('data-column-1').split(",")
                    },
                    {
                        ...dataset2,
                        data: data3.attr('data-column-2').split(",")
                    }
                ]
                chartElement.parent().addClass('chart-container--narrower');
                table.eq(2).fadeIn(200).siblings().hide();

            } else if (value === 'year') {
                chart.data.labels = data4.attr('data-values').split(",");
                chart.data.datasets = [
                    {
                        ...dataset2,
                        data: data4.attr('data-column-1').split(","),
                        label: ''
                    }
                ];
                chartElement.parent().addClass('chart-container--narrower');
                table.eq(3).fadeIn(200).siblings().hide();
            }

        }

        if(chartType == 'pie') {

            let data1_parse = (new Function("return [" + data1.attr('data-column-1') + "];")()),
                data2_parse = (new Function("return [" + data2.attr('data-column-2') + "];")()),
                data3_parse = (new Function("return [" + data3.attr('data-column-2') + "];")()),
                data4_parse = (new Function("return [" + data4.attr('data-column-1') + "];")())

            if(value == 'month') {

                chart.data.labels = data1.attr('data-values').split(",");
                chart.data.datasets[0].data = data1_parse;

            } else if(value == 'quarth') {

                chart.data.labels = data2.attr('data-values').split(",");
                chart.data.datasets[0].data = data2_parse;

            } else if(value == 'half-year') {

                chart.data.labels = data3.attr('data-values').split(",");
                chart.data.datasets[0].data = data3_parse;

            } else if (value === 'year') {
                chart.data.labels = data4.attr('data-values').split(",");
                chart.data.datasets[0].data = data4_parse;
            }

        }

        chart.update();

    });

});

// b news list

$('.b-news-list__header').click(function(e){

    e.preventDefault();

    $(this).parent().toggleClass('b-news-list__item--active');
    $(this).siblings('.b-news-list__content').slideToggle(200);

});

let bScrollbar;

$('.b-chart-score__content-scroll, .b-table__scroll, .b-box-primary__table-scroll, .b-report-list__box-scroll, .car-dealer-branches-summary-over').each(function(){
    bScrollbar = OverlayScrollbars($(this)[0], {
        scrollbars: {
            clickScroll: true
        }
    });
});

let bNavPage;

$('.b-nav-page').each(function(){

    let activeElement = $(this).find('.active');
    let offsetLeft;
    let activeElementWidth;
    let activeElementOffsetLeft;
    let widthWindow;

    if (activeElement.offset() && activeElement.outerWidth()) {
        activeElementWidth = activeElement.outerWidth();
        activeElementOffsetLeft = activeElement.offset().left;
        widthWindow = $(window).outerWidth();
        offsetLeft = activeElementOffsetLeft - ((widthWindow - activeElementWidth) / 2);
    }

    bNavPage = OverlayScrollbars($(this)[0], {
        scrollbars: {
            clickScroll: true
        }
    });

    const { viewport } = bNavPage.elements();

    if (offsetLeft) {
        viewport.scrollTo({ left: offsetLeft });
    }
});

// b faq

$('.b-faq__header').click(function(e){

    e.preventDefault();

    $(this).parent().toggleClass('b-faq__item--active');
    $(this).siblings('.b-faq__content').slideToggle(200);

});

// form range

$(".form-range__input").ionRangeSlider();

// form check

$('.form-check--select-all input').on('change', function(){

    let value = $(this).prop('checked');

    if(value) {
        $(this).parent().siblings('.form-check').children('input').prop('checked', true);
    } else {
        $(this).parent().siblings('.form-check').children('input').prop('checked', false);
    }

});

// m product card

// $('.m-product-card__slider').each(function(){
//
//     let thumb = $(this).children('.m-product-card__slider-thumb').children('.swiper'),
//         image = $(this).children('.m-product-card__slider-big').children('.swiper');
//
//     let swiper = new Swiper(thumb[0], {
//         slidesPerView: 'auto',
//         direction: 'vertical',
//         watchSlidesProgress: true
//     });
//
//     let swiper2 = new Swiper(image[0], {
//         slidesPerView: 1,
//         thumbs: {
//             swiper: swiper
//         },
//         modules: [Thumbs]
//     });
//
// });

function initializeSwipers() {
    $('.product-slider').each(function () {

        let thumb = $(this).children('.m-product-card__slider-thumb').children('.swiper'),
            image = $(this).children('.m-product-card__slider-big').children('.swiper');

        let swiperThumb = new Swiper(thumb[0], {
            // slidesPerView: 'auto',
            slidesPerView: 3,
            direction: 'vertical',
            watchSlidesProgress: true,
            navigation: {
                nextEl: document.querySelector(".swiper-product-button-next"),
                prevEl: document.querySelector(".swiper-product-button-prev"),
            },
            modules: [Navigation]
            // slidesPerView: 3,
        });

        let swiperImage = new Swiper(image[0], {
            slidesPerView: 1,
            thumbs: {
                swiper: swiperThumb
            },
            modules: [Thumbs]
        });

        document.querySelector(".swiper-product-button-next").addEventListener('click', function () {
            swiperThumb.slideNext();
            swiperImage.slideNext();
        });

        document.querySelector(".swiper-product-button-prev").addEventListener('click', function () {
            swiperThumb.slidePrev();
            swiperImage.slidePrev();
        });
    });
}

// Funkcja do niszczenia istniejących swiperów
function destroySwipers() {
    document.querySelectorAll('.product-slider').forEach(function (slider) {
        if (slider.swiperThumb) {
            slider.swiperThumb.destroy(true, true);
            slider.swiperThumb = null;
        }
        if (slider.swiperImage) {
            slider.swiperImage.destroy(true, true);
            slider.swiperImage = null;
        }
    });
}

// Funkcja do ponownej inicjalizacji
window.reinitializeSwipers = function () {
    destroySwipers();
    initializeSwipers();
}

// b box primary - calendar

$('.b-calendar').each(function(){
    let locale = $(this).data('locale');
    let availableDates = $(this).data('available-dates');
    let dataPopups = $(this).data('popups');
    let url = $(this).data('path');
    const calendar = new VanillaCalendar('.b-calendar', {
        settings: {
            lang: locale,
            range: {
                disableAllDays: true,
                enabled: availableDates,
                disablePast: true
            }
        },
        popups: dataPopups,
        actions: {
            clickDay(event, dates) {
                event.stopPropagation();
                let selectedDate = dates.selectedDates[0];
                let formData = new FormData();
                formData.append('selectedDate',selectedDate);
                $.ajax({
                    type: "POST",
                    cache: false,
                    contentType: false,
                    processData: false,
                    url: url,
                    data: formData,
                    success: function (data) {
                        $('#calendar-ajax-data').html(data);
                    }
                });
            },
        },
    });

    calendar.init();
});

let bOfferGridSlider;

$('.b-offer-grid .swiper').each(function(){

    bOfferGridSlider = new Swiper($(this)[0], {
        slidesPerView: 'auto',
        loop: true,
        pagination: {
            el: $(this).siblings('.swiper-pagination')[0],
            clickable: true
        },
        modules: [Pagination]
    });

});

$('.b-offer-grid__nav li').click(function(e){

    e.preventDefault();

    let items = $(this).closest('.b-offer-grid').find('.b-offer-grid__item'),
        index = $(this).index();

    $(this).addClass('active').siblings().removeClass('active');
    items.eq(index).fadeIn(200).siblings().hide();

});
